
import { defineComponent, onMounted, reactive, ref, watch } from "vue";
import Datatable from "@/components/kt-datatable/Datatable.vue";
import AddContractTemplateItem from "./components/AddContractTemplateItem.vue";
import AdjustItemWeightByAddContract from "./components/AdjustItemWeightByAddContract.vue";
import { Modal } from "bootstrap";
import { getLocalTimeString, getUtcTimeString } from "@/core/utils/timeString";
import { discountRuleModeMap } from "@/core/services/responsesModel/contract";
import { getFacilityBySpace } from "@/core/services/api/facilities";
import {
  updateContract,
  getContractByGuid,
} from "@/core/services/api/contract";
import {
  addContractDiscount,
  deleteContractDiscount,
} from "@/core/services/api/contractDiscount";
import {
  FacilityExtend,
  FacilityType,
} from "@/core/services/responsesModel/facilityExtend";
import localStorage from "@/core/services/LocalstorageService";
import {
  AddContractTemplateDiscountListModel,
  AddContractTemplateItemForm,
} from "./model";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { useRouter, useRoute } from "vue-router";

// 列表資料Model
interface tableDataModel {
  id: string;
  name: string;
  ruleMode: string;
  ruleJsons: Array<string>;
  beginTime: string;
  endTime: string;
}

export default defineComponent({
  components: {
    Datatable,
    AddContractTemplateItem,
    AdjustItemWeightByAddContract,
  },
  setup() {
    // 樣板名稱
    const contractName = ref<string>("");
    const initContractDiscounts: any = reactive([]);
    const router = useRouter();
    const route = useRoute();
    const contractTemplateGuid: any = route.params.contractTemplateGuid;
    // contractTemplateNameformRef
    const contractTemplateNameFormRef = ref<null | HTMLFormElement>(null);
    // 合約樣板名稱表單
    const contractTemplateNameForm = reactive({
      name: "",
      remark: ""
    });
    const requestRuleModes: any = reactive([]);
    // 合約樣板名稱驗證規則
    const contractTemplateNameRules = {
      name: [
        {
          required: true,
          message: "請填寫合約樣板名稱",
          trigger: "blur",
        },
      ],
    };
    // 設施列表
    const facilities: Array<FacilityExtend> = reactive([]);
    // 空間guid
    const spaceGuid = localStorage.getCurrentSpaces()?.space.guid;
    // 新增合約樣板優惠列表
    const addContractTemplateDiscountList: any = reactive([]);
    // 新增合約優惠項目視窗
    let addContractTemplateItemModal;
    // 列表資料
    const tableData = reactive<Array<tableDataModel>>([]);
    // 列表標題
    const tableHeader = [
      { key: "name", name: "名稱", sortable: true },
      { key: "ruleMode", name: "規則模式", sortable: true },
      { key: "ruleJsons", name: "參數", sortable: true },
      { key: "beginTime", name: "起始時間", sortable: true },
      { key: "endTime", name: "結束時間", sortable: true },
      { key: "operate", name: "操作" },
    ];

    // 添加優惠項目
    const addItem = (addForm: AddContractTemplateItemForm) => {
      let newData: any = {
        guid: null,
        id: Math.floor(Math.random() * new Date().getTime()).toString(),
        ruleMode: addForm.ruleMode,
        ruleJson: addForm.ruleJson,
        beginTime: addForm.beginTime,
        endTime: addForm.endTime,
      };
      addContractTemplateDiscountList.push(newData);
    };

    // 刪除優惠項目
    const deleteItem = (deleteItem: tableDataModel) => {
      let filterList = addContractTemplateDiscountList.filter(
        (item) => item.id !== deleteItem.id
      );
      addContractTemplateDiscountList.splice(
        0,
        addContractTemplateDiscountList.length,
        ...filterList
      );
    };

    // 新增樣板項目事件
    const onAddContractTemplateItem = (
      addForm: AddContractTemplateItemForm
    ) => {
      addContractTemplateItemModal.hide();
      addItem(addForm);
    };

    // 取得空間設施
    const handleGetFacilityBySpace = async () => {
      return (await getFacilityBySpace(spaceGuid!)).filter(
        (facility) => facility.facility.type == FacilityType.Facility
      );
    };

    // 根據設施唯一識別查詢設施名稱
    const getFacilityNameByGuid = (guid) => {
      let filterFacilities = facilities.filter(
        (facility) => facility.facility.guid == guid
      );
      if (filterFacilities.length > 0) {
        return filterFacilities[0].facility.name;
      }
      return "";
    };

    // 取得複選設施顯示參數
    const getCheckFacilitiesShowRuleJsons = (targets: Array<string>) => {
      let show = "";
      console.log("targets",targets)
      targets.forEach((target) => {
        show = show + `${getFacilityNameByGuid(target)},`;
      });
      show = show.substr(0, show.length - 1);
      return show;
    };

    // 取得顯示參數
    const getShowRuleJson = (
      addContractTemplateDiscount: AddContractTemplateDiscountListModel
    ) => {
      let showRuleJson: Array<string> = [];
      const ruleJson: any = JSON.parse(addContractTemplateDiscount.ruleJson);
      delete ruleJson.name;
      let showTarget = "指定設施:";
      console.log("addContractTemplateDiscount.ruleMode",addContractTemplateDiscount.ruleMode)
      console.log("ruleJson",ruleJson)
      switch (addContractTemplateDiscount.ruleMode) {
        // 全額折抵
        case "FullAmount":
          showRuleJson.push("全額折抵");
          break;
        // 預付金額折抵
        case "PrepaidAmount":
          showRuleJson.push(`預付金額:${ruleJson.maxDiscountAmount}`);
          break;
        // 指定設施全額折抵
        case "TargetFullAmount":
          showRuleJson.push(
            `指定設施:${getCheckFacilitiesShowRuleJsons(ruleJson.targets)}`
          );
          break;
        // 指定設施時間單位折抵
        case "TargetTimespan":
          showRuleJson.push(
            `指定設施:${getCheckFacilitiesShowRuleJsons(ruleJson.targets)}`
          );
          showRuleJson.push(`時間單位:${ruleJson.maxDiscountTimeSpan}`);
          break;
        // 指定設施特惠價格
        case "TargetSpecialTimespanAmount":
          showRuleJson.push(
            `指定設施:${getFacilityNameByGuid(ruleJson.target)}`
          );
          showRuleJson.push(`特惠價格:${ruleJson.specialTimespanAmount}元`);
          break;
        // 指定設施折數優惠
        case "TargetsPercent":
          showRuleJson.push(
            `指定設施:${getCheckFacilitiesShowRuleJsons(ruleJson.targets)}`
          );
          showRuleJson.push(`折數優惠:${ruleJson.percent * 100}折`);
          break;
      }

      return showRuleJson;
    };

    // 取得合約樣板資料
    const setContractTemplate = async () => {
      let contract: any = await getContractByGuid(contractTemplateGuid);
      initContractDiscounts.splice(
        0,
        initContractDiscounts.length,
        ...contract.contractDiscounts
      );
      contractTemplateNameForm.name = contract.name;
      contractTemplateNameForm.remark = contract.remark;
      contract.contractDiscounts.forEach((contractDiscount) => {
        addContractTemplateDiscountList.push({
          guid: contractDiscount.guid,
          id: Math.floor(Math.random() * new Date().getTime()).toString(),
          ruleMode: contractDiscount.ruleMode,
          ruleJson: contractDiscount.ruleJson,
          beginTime: contractDiscount.beginTime,
          endTime: contractDiscount.endTime,
        });
      });
    };

    // 設置資料表
    const setTableData = () => {
      tableData.splice(0);
      for (const addContractTemplateDiscount of addContractTemplateDiscountList) {
        tableData.push({
          id: addContractTemplateDiscount.id,
          name: JSON.parse(addContractTemplateDiscount.ruleJson).name,
          ruleMode: discountRuleModeMap[addContractTemplateDiscount.ruleMode],
          ruleJsons: getShowRuleJson(addContractTemplateDiscount),
          beginTime: getLocalTimeString(addContractTemplateDiscount.beginTime),
          endTime: getLocalTimeString(addContractTemplateDiscount.endTime),
        });
      }
    };

    // 更新合約樣板
    const updateContractTemplate = () => {
      if (!contractTemplateNameFormRef.value) {
        return;
      }
      contractTemplateNameFormRef.value!.validate(async (valid) => {
        if (valid) {
          await Swal.fire({
            title: "確定要更新合約樣板嗎?",
            icon: "warning",
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: `確定`,
            denyButtonText: `取消`,
          }).then(async (result) => {
            if (result.isConfirmed) {
              let updateContractRequest = {
                name: contractTemplateNameForm.name,
                remark:  contractTemplateNameForm.remark,
                discountPriority: {
                  discountRuleModes: requestRuleModes,
                },
              };
              await updateContract(
                spaceGuid!,
                contractTemplateGuid!,
                updateContractRequest
              );
              let initContractDiscountsGuids = initContractDiscounts.map(
                (o) => o.guid
              );
              let discountGuids = addContractTemplateDiscountList.map(
                (o) => o.guid
              );
              for (const initGuid of initContractDiscountsGuids) {
                if (!discountGuids.includes(initGuid)) {
                  await deleteContractDiscount(
                    spaceGuid!,
                    contractTemplateGuid!,
                    initGuid
                  );
                }
              }
              let addContractDiscounts = addContractTemplateDiscountList.filter(
                (discount) => !discount.guid
              );

              for (const discount of addContractDiscounts) {
                let addContractDiscountRequest = {
                  ruleMode: discount.ruleMode,
                  ruleJson: discount.ruleJson,
                  beginTime: getUtcTimeString(discount.beginTime),
                  endTime: getUtcTimeString(discount.endTime),
                };
                await addContractDiscount(
                  spaceGuid!,
                  contractTemplateGuid!,
                  addContractDiscountRequest
                );
              }

              await Swal.fire("更新成功!", "", "success");
              router.go(-1);
            }
          });
        }
      });
    };

    const changeRuleModes = (ruleModes) => {
      requestRuleModes.splice(0, requestRuleModes.length, ...ruleModes.value);
    };

    watch(
      addContractTemplateDiscountList,
      () => {
        setTableData();
      },
      {
        deep: true,
        immediate: true,
      }
    );

    onMounted(() => {
      addContractTemplateItemModal = new Modal(
        document.getElementById("addContractTemplateItem-modal")
      );
    });

    const init = async () => {
      facilities.splice(
        0,
        facilities.length,
        ...(await handleGetFacilityBySpace())
      );
      await setContractTemplate();
    };
    init();

    return {
      contractName,
      tableHeader,
      tableData,
      addItem,
      deleteItem,
      onAddContractTemplateItem,
      contractTemplateNameFormRef,
      contractTemplateNameForm,
      contractTemplateNameRules,
      updateContractTemplate,
      addContractTemplateDiscountList,
      changeRuleModes,
    };
  },
});
