import ApiService from "@/core/services/ApiService";

// 更新合約樣板折扣
export function updateContractDiscount(spaceGuid, contractGuid, contractDiscountGuid, request) {
    return new Promise((resolve, reject) => {
        ApiService.patch(`Space/${spaceGuid}/Contract/${contractGuid}/ContractDiscount/${contractDiscountGuid}`, request).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}

// 新增合約樣板折扣
export function addContractDiscount(spaceGuid, contractGuid, request) {
    return new Promise((resolve, reject) => {
        ApiService.post(`Space/${spaceGuid}/Contract/${contractGuid}/ContractDiscount`, request).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}

// 刪除合約樣板折扣
export function deleteContractDiscount(spaceGuid, contractGuid, contractDiscountGuid) {
    return new Promise((resolve, reject) => {
        ApiService.delete(`Space/${spaceGuid}/Contract/${contractGuid}/ContractDiscount/${contractDiscountGuid}`).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}